import React, {Component} from 'react'
import _                  from 'lodash'
import PropTypes          from 'prop-types'
import OpenFolder         from './open-folder'
import { getTrackById }   from '../drawer-items'
import PhotoGallery       from './photo-gallery'
import PhotoModal         from './photo-modal'
import GlobalMenuIcon     from './global-menu-icon'
import {photoCollectionIsLockedByTrackID} from '../user'

export default class FolderBrowser extends Component {

    constructor(props, b){
        super(props, b)

        let { search } = props.location;
        const searchParams = new URLSearchParams(search);
        let trackId = searchParams.get("track");
        let photoId = searchParams.get("photo");

        const locked = photoCollectionIsLockedByTrackID(trackId)

        let track  = getTrackById(trackId)

        let photos = this.extractPhotos(track)

        photos = photos || []
        let photo  = this.getPhoto(photoId, photos)

        this.state = {photo, track, photos, photoId, locked}

        this.showModal        = this.showModal.bind(this)
        this.hideModal        = this.hideModal.bind(this)
        this.showCurrentPhoto = this.showCurrentPhoto.bind(this)
        this.closeFolder      = this.closeFolder.bind(this)
        this.backToCabinet    = this.backToCabinet.bind(this)
    }
    getPhoto(id, photos){
        return _.find(photos, {id}) || null
    }
    extractPhotos(track){
        return this.extractPhotosFromTrack(track)
    }
    updateUrl({photo, track}){
        const { pathname } = this.props.location;
        const { history } = this.props;

        const params = new URLSearchParams();

        if (track && track.id) {
            params.set("track", track.id);
        }

        if (photo && photo.id) {
            params.set("photo", photo.id);
        }

        const newUrl = `${pathname}?${params.toString()}`;

        history.replace(newUrl);
    }
    showModal(photo){
        const { pathname } = this.props.location;
        const { history } = this.props;
        const { track } = this.state;

        const params = new URLSearchParams();

        if (track && track.id) {
            params.set("track", track.id);
        }

        if (photo && photo.id) {
            params.set("photo", photo.id);
        }

        const newUrl = `${pathname}?${params.toString()}`;
        //TODO CHECK IF NEEDS TO BE MODAL
        history.push(newUrl);
    }
    showCurrentPhoto(photo){
        this.setState({photo, photoId:photo.id})
        this.updateUrl({photo, track:this.state.track})
    }
    hideModal(){
        this.props.history.goBack()
    }
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        let { search } = nextProps.location;
        const searchParams = new URLSearchParams(search);
        let trackId = searchParams.get("track");

        let track  = getTrackById(trackId)
        let photos = this.extractPhotos(track)

        let photoId = searchParams.get("photo");
        if (photoId !== nextState.photoId) {
            let photo = this.getPhoto(photoId, photos)
            this.setState({photoId, photo, track, photos})
        }
    }

    componentDidMount(){
        const {locked} = this.state
        if (locked) {
            const {history} = this.props
            history.push('/')
            window.subs()
        }
    }

    closeFolder(){
        const { history, location } = this.props;
        const { track } = this.state;

        const params = new URLSearchParams();

        if (track && track.id) {
            params.set("track", track.id);
        }
        const newUrl = `/info-card?${params.toString()}`;
        history.push(newUrl);
    }

    backToCabinet(){
        let {history} = this.props
        history.push('/drawer')
    }

    /* to be overriden */
    contentType(){
        console.error("Need to provide a content type!")
        debugger;
    }

    /* to be overriden */
    selectedTab(){
        console.error("don't know the selected tab")
        return null
    }

    /* to be overriden */
    extractPhotosFromTrack(/* track*/){
        console.error("don't know how to extract photos from track!")
        return null
    }

    /* to be overriden for documents */
    modalForPhotoAndPhotos(photo, photos){
        return (<PhotoModal photo={photo} collection={photos}
                            location={this.props.location}
                            onUpdate={this.showCurrentPhoto}
                            onDismiss={this.hideModal} /> )
    }


    render(){
        let {track, photoId, photo, photos, locked} = this.state
        if (locked) return null
        let selected = this.selectedTab()
        return (
            <div className="info-card-section">
              <OpenFolder track={track} selected={selected} onClose={this.closeFolder}>
                <PhotoGallery photos={photos} onSelect={this.showModal} />
              </OpenFolder>
              {photoId ? this.modalForPhotoAndPhotos(photo, photos) : null }
              <div className="up-arrow-wrapper">
                <div className="up-arrow" onClick={this.backToCabinet}></div>
              </div>
              <GlobalMenuIcon />
            </div>
        )
    }
}

FolderBrowser.contextTypes = {
    router: PropTypes.object.isRequired
}
