import React, { Component } from "react";

// Componente de límite de error para capturar errores de renderizado
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Actualizar el estado para que el siguiente renderizado muestre la UI alternativa
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Capturar detalles del error
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // Registrar el error en la consola con detalles
    console.error("RENDER ERROR CAUGHT BY ERROR BOUNDARY:", error);
    console.error("Component Stack:", errorInfo.componentStack);

    // Aquí podrías enviar el error a un servicio de registro de errores
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            color: "red",
            border: "2px solid red",
            padding: "15px",
            margin: "15px",
            backgroundColor: "#fff3f3",
            borderRadius: "5px",
            fontFamily: "sans-serif",
          }}
        >
          <h2>Rendering Error Detected</h2>
          <p>
            <strong>Error:</strong>{" "}
            {this.state.error && this.state.error.toString()}
          </p>
          <details style={{ whiteSpace: "pre-wrap", margin: "10px 0" }}>
            <summary>Component Stack Details</summary>
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
          <button
            onClick={() => window.location.reload()}
            style={{
              padding: "8px 15px",
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Reload Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
