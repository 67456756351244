import React, {Component} from 'react'
import { withRouter } from 'react-router-dom';

class GlobalMapIcon extends Component {
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick(){
        const { history } = this.props;
        history.push('/map');
    }
    render() {
        return (
            <div className="global-map-icon" onClick={this.onClick}></div>
        )
    }
}

export default withRouter(GlobalMapIcon);
