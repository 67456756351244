import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GlobalMenuIcon from "./global-menu-icon";
import GlobalMapIcon from "./global-map-icon";
import BackNextSwitch from "./back-next-switch";
import { getCurrentDateAsQueryString } from "../currentDate";
import { freePassToken, getUserInfo } from "../services/api";
import { isUserAuthenticated, userHasSubscription } from "../user";
import { updateEntries } from "../actions/entries";
import { getContrarianPageData } from "../services/api";
import { parseOnlyNumbers } from "../util/strings";
import _ from "lodash";
import Player from "./new-player/new-player";
import Mp3Player from "./new-player/mp3-player";
import { connect } from "react-redux";

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: props.path,
      playingTrack: props.playingTrack,
    };

    this.find = this.find.bind(this);
    this.home = this.home.bind(this);
    this.cabinet = this.cabinet.bind(this);
    this.infocard = this.infocard.bind(this);
    this.timeline = this.timeline.bind(this);
    this.showInstruction = this.showInstruction.bind(this);
    this.contrarian = this.contrarian.bind(this);
    this.store = this.store.bind(this);
  }

  componentDidMount() {
    getContrarianPageData().then((contrarianPaperData) => {
      updateEntries(contrarianPaperData);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      location: nextProps.path,
      playingTrack: nextProps.playingTrack,
    });
  }

  // Método actualizado para usar history.push directamente
  go(url) {
    this.props.history.push(url);
  }

  home() {
    this.go("/welcome");
  }

  find() {
    this.props.history.push({
      pathname: "/search",
      state: { modal: true, prevLocation: this.props.location }, // Pass prevLocation
    });
  }

  cabinet() {
    this.go("/drawer?" + getCurrentDateAsQueryString());
  }

  infocard() {
    this.go("/info-card?" + getCurrentDateAsQueryString());
  }

  timeline() {
    // const url = process.env.NODE_ENV === 'development'
    //     ? `/timeline?day=1&month=1&year=${new Date().getFullYear() + 1}`
    //     : `/timeline?${getCurrentDateAsQueryString()}`;
    const url = `/timeline?day=1&month=1&year=${new Date().getFullYear() + 1}`;
    this.go(url);
  }

  contrarian() {
    const { pathname } = document.location;
    const routeName = pathname.split("/")[1];
    if (routeName === "news") {
      const iframe = document.getElementById("newspaper");
      if (!iframe) return;
      iframe.contentWindow.postMessage(
        { message: 1, type: "GOTO_NYATC_FRONT_PAGE" },
        "*"
      );
      window.history.replaceState(null, "", "/news/1");
      return;
    }
    this.go("/news/1");
  }

  store() {
    const { storeLink } = this.props;
    if (storeLink) {
      window.open(storeLink, "_blank");
    }
  }

  getButton(path) {
    let table = {
      "/": "timeline",
      "/drawer": "timeline",
      "/info-card": "timeline",
      "/timeline": "home",
      "/news": "timeline",
    };
    let found;
    _.each(table, (button, match) => {
      if (path.indexOf(match) === 0) found = button;
    });
    return found;
  }

  hideShadow(path) {
    let table = {
      "/": true,
      "/drawer": true,
      "/timeline": true,
      "/timeline-months": true,
    };
    return table[path];
  }

  viewRender(button) {
    if (this.props.path !== "/drawer") {
      if (button == "timeline" || button == "home") {
        return "cabinet";
      } else if (button == "cabinet") {
        return "home";
      }
    } else {
      return "home";
    }
  }

  showInstruction() {
    window.siteInstruction();
  }

  renderPlayer(item) {
    const { themeAperance, isTrackMp3 } = item;
    const { playingTrack } = this.props;

    if (!isTrackMp3) {
      return <Player themeAperance={themeAperance} />;
    } else {
      return (
        <Mp3Player playingTrack={playingTrack} themeAperance={themeAperance} />
      );
    }
  }

  render() {
    const { pagesData = [], path, playingTrack } = this.props;
    const isDarkMode =
      pagesData.find(
        (data) =>
          data.darkMode &&
          parseOnlyNumbers(data.title) == parseOnlyNumbers(path)
      ) || null;
    const themeAperance = isDarkMode ? "dark-mode" : "light-mode";
    let button = this.getButton(this.props.path);
    let button2 = this.viewRender(button);
    const showShadow = !this.hideShadow(this.props.path);
    let freeActivePass =
      freePassToken() !== null &&
      freePassToken().validity &&
      !userHasSubscription()
        ? true
        : false;
    let isTrackMp3 =
      !playingTrack || (playingTrack && playingTrack.orastream) ? false : true;

    const isAuthenticated = isUserAuthenticated();
    let info = getUserInfo();
    let name =
      info.user_metadata.customFirstname ||
      info.email ||
      info.nickname ||
      info.username ||
      info.given_name ||
      "";
    name = name.split(" ")[0] || "";
    const firstLetter = name[0]?.toUpperCase() || "";

    return (
      <div
        className={`global-top-bar-wrapper ${themeAperance} ${
          isAuthenticated ? "authenticated" : ""
        }`}
      >
        {isAuthenticated ? (
          <div className="user-icon">{firstLetter}</div>
        ) : null}
        <div className="global-top-bar">
          {this.renderPlayer({ isTrackMp3, themeAperance })}
          <div className="find" onClick={this.find} />
          {/* { button ? */}
          <div className="global-top-bar-btn">
            <div className="contrarian-btn" onClick={this.contrarian} />
            <div className="store-btn" onClick={this.store} />
            <div className={button} onClick={this[button]} />
            <div className={button2} onClick={this[button2]} />
          </div>
          {/* : null } */}
          {/* { showShadow && <div className="shadow" /> } */}
        </div>
        <GlobalMenuIcon />
        <GlobalMapIcon />
        <BackNextSwitch />
        <div className="find" onClick={this.find} />
        {freeActivePass && (
          <div className="instruction-btn" onClick={this.showInstruction}></div>
        )}
      </div>
    );
  }
}

// Reemplazar contextTypes con withRouter
const mapStateToProps = function (state) {
  return {
    pagesData: state.contrarianPage.all,
    playingTrack: state.tracks.playingTrack,
    storeLink: state.commonValues.storeLink,
  };
};

export default withRouter(connect(mapStateToProps)(TopBar));