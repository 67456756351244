import React, {Component} from 'react'
import { withRouter } from 'react-router-dom';

class GlobalMenuIcon extends Component {
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    onClick(){
      let { history } = this.props;
      const prevLocation = this.props.location;
      history.push({
        pathname: "/menu",
        state: {
          modal: true,
          prevLocation: prevLocation,
        },
      });
      //history.push('/menu')
    }
    render() {
        return (
            <div className="global-menu-icon" onClick={this.onClick}></div>
        )
    }
}

export default withRouter(GlobalMenuIcon);
