import React, { Component, createRef } from "react";
import { inverseScale } from "../scale.js";
import { updateArticleView } from "../actions/articleView";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

let positions = {
  left: "96px",
  center: "114px",
  right: "132px",
};

class BackNextSwitch extends Component {
  constructor(props) {
    super(props);

    this.elementRef = createRef();
    this.switchRef = createRef();
    this.click = this.click.bind(this);

    this.lastNavigationTime = 0;
    this.isHandlingNavigation = false;

    this.customHistory = [];
    this.customHistoryIndex = -1;
  }

  componentDidMount() {
    this.addToCustomHistory(this.props.location);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      !this.isHandlingNavigation
    ) {
      this.addToCustomHistory(this.props.location);
    }
  }

  addToCustomHistory(location) {
    if (this.customHistoryIndex < this.customHistory.length - 1) {
      this.customHistory = this.customHistory.slice(
        0,
        this.customHistoryIndex + 1
      );
    }

    const lastLocation = this.customHistory[this.customHistoryIndex];
    if (
      lastLocation &&
      lastLocation.pathname === location.pathname &&
      lastLocation.search === location.search
    ) {
      return;
    }

    this.customHistory.push({
      pathname: location.pathname,
      search: location.search,
    });
    this.customHistoryIndex = this.customHistory.length - 1;
  }

  getPreviousPage() {
    if (this.customHistoryIndex > 0) {
      this.customHistoryIndex--;
      return this.customHistory[this.customHistoryIndex];
    }
    return null;
  }

  getNextPage() {
    if (this.customHistoryIndex < this.customHistory.length - 1) {
      this.customHistoryIndex++;
      return this.customHistory[this.customHistoryIndex];
    }
    return null;
  }

  shouldNavigate() {
    if (this.isHandlingNavigation) return false;

    const now = Date.now();
    if (now - this.lastNavigationTime < 300) {
      return false;
    }
    this.lastNavigationTime = now;
    return true;
  }

  toggle(key, then) {
    if (this._timeout) return;

    this.switchRef.current.style.left = positions[key];
    this._timeout = setTimeout(() => {
      this.switchRef.current.style.left = positions.center;
      this._timeout = null;

      if (!this.shouldNavigate()) return;

      this.isHandlingNavigation = true;

      if (then === "back") {
        const { isArticleView } = this.props;
        const showingArticle = isArticleView && isArticleView.show;

        if (showingArticle) {
          this.props.updateArticleView(false);
        }

        const previousPage = this.getPreviousPage();

        if (previousPage) {
          const { pathname, search } = previousPage;
          this.props.history.replace(`${pathname}${search}`);
        } else {
          this.props.history.goBack();
        }
      } else if (then === "forward") {
        if (this.props.isArticleView && this.props.isArticleView.show) {
          this.props.updateArticleView(false);
        }

        const nextPage = this.getNextPage();

        if (nextPage) {
          const { pathname, search } = nextPage;
          this.props.history.replace(`${pathname}${search}`);
        } else {
          this.props.history.goForward();
        }
      }

      setTimeout(() => {
        this.isHandlingNavigation = false;
      }, 100);
    }, 200);
  }

  click(e) {
    let width = 272,
      half = width / 2;
    let left = e.pageX * inverseScale() - this.elementRef.current.offsetLeft;

    if (left < half) this.toggle("left", "back");
    else this.toggle("right", "forward");
  }

  render() {
    return (
      <div
        className="back-next-switch"
        ref={this.elementRef}
        onClick={this.click}
      >
        <div className="back-next-switch-wrapper">
          <div className="background" />
          <div className="back" />
          <div className="next" />
          <div className="switch" ref={this.switchRef} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    isArticleView: state.articleView,
  };
};

export default withRouter(
  connect(mapStateToProps, { updateArticleView })(BackNextSwitch)
);
