import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Auth from '../services/auth'
import { withRouter } from 'react-router-dom'

class AccountPulldown extends Component {
    constructor(props, context){
        super(props, context)

        this.onClick           = this.onClick.bind(this)
        this.clickAccount      = this.clickAccount.bind(this)
        this.clickSubscription = this.clickSubscription.bind(this)
    }
    clickAccount(){
        let pathname = '/account'
        this.props.history.push(pathname)
    }
    clickSubscription(){
        this.props.history.push('/account?screen=subscription');
    }
    onClick(){
        this.props.onClose()
    }
    componentDidMount(){
        document.addEventListener('click', this.onClick)
    }
    componentWillUnmount(){
        document.removeEventListener('click', this.onClick)
    }
    render() {
        return (
            <div className="user-uptions">
              <div className="link" onClick={this.clickAccount}>ACCOUNT</div>
              <div className="link" onClick={this.clickSubscription}>SUBSCRIPTION</div>
              <div className="link" onClick={Auth.logout}>LOG OUT</div>
            </div>
        )
    }
}

AccountPulldown.contextTypes = {
    router: PropTypes.object.isRequired
}

export default withRouter(AccountPulldown);
